import { capitalizeFirstLetter, formatDashToWhitespace } from '@/helpers/format'
import i18n from '@/i18n'
import { $cookies } from '@/main'
import base from '@/router/base'
import customer from '@/router/customer'
import supplier from '@/router/supplier'
import store from '@/store/store'
import { createRouter, createWebHistory } from 'vue-router'

let customerRoutes = customer
let routes

base[1].children = [...base[1].children, ...supplier, ...customerRoutes]
routes = base

let router = createRouter({
  history: createWebHistory(),
  routes
})

const isAuthenticated = (to) => {
  return (
    $cookies.get(process.env.VUE_APP_REFRESH_TOKEN_COOKIE) !== null &&
    to.meta.userType.includes($cookies.get('userType'))
  )
}

const showSubNavigation = (name) => {
  if (name === 'customerChat' || name === 'supplierChat') {
    store.commit('navigation/showSubNavigation', false)
  } else if (
    ['clientOverview', 'clientPaymentBilling', 'supplierSettings'].includes(
      name
    )
  ) {
    store.commit('navigation/showSubNavigation', true)
  }
}

router.beforeEach((to, from, next) => {
  if (to.name !== from.name) {
    store.dispatch('loading/startLoader', null, { root: true })
  }
  if (to.name === 'clientClustersOverview') {
    to.meta.breadcrumb = [
      ...to.meta.breadcrumb,
      {
        name: capitalizeFirstLetter(
          formatDashToWhitespace(to.params.pillarName)
        ),
        linkTo: 'clientClustersOverview'
      }
    ]
  }
  if (to && to.meta && to.meta.breadcrumb !== undefined) {
    const breadcrumb = to.meta.breadcrumb
    document.title = breadcrumb[breadcrumb.length - 1].name
  } else if (to && to.name !== undefined) {
    let pageName = to.name.split(/(?=[A-Z])/).join(' ')
    document.title = pageName
  } else {
    document.title = 'EasyTranslate'
  }

  if (process.env.VUE_APP_ENV === 'prod') {
    const _hsq = (window._hsq = window._hsq || [])
    _hsq.push(['setPath', to.path])
    _hsq.push(['trackPageView'])
  }

  document.title = 'EasyTranslate'
  if (
    to.matched.some((record) => {
      return !to.meta.isPublicRoute && record.name == to.name
    })
  ) {
    if (isAuthenticated(to)) {
      if (
        (from.path === `/${i18n.i18n.global.locale.value}` ||
          from.path === `/${i18n.i18n.global.locale.value}/accounts`) &&
        store.state.workspace.currentUser.attributes.mfa['2fa'].is_enabled
      ) {
        // store.dispatch('modals/triggerMfaVerificationModal', null, { root: true })
      }
      showSubNavigation(to.name)
      store.commit('navigation/setBaseRoute', to.meta.baseRoute)
      next()
    } else {
      let query = { redirect: to.path, ...to.query }
      if (to.query.team) {
        query = { ...query, team: to.query.team }
      }
      next({
        name: 'BaseLogin',
        params: { locale: i18n.i18n.global.locale.value },
        query: query
      })
    }
  } else {
    next()
  }
})

router.afterEach((to, from) => {
  if (to.name !== from.name) {
    store.dispatch('loading/stopLoader', null, { root: true })
  }
})

export { router }

import { TokenService } from '@/api/new/services/storage.service'
import i18n from '@/i18n'

// Share
import Login from '@/views/client/newGrid/Login'
import Base from '@/views/shared/Base'

const ForgotPassword = () =>
  import(
    /* webpackChunkName: "forgot-password" */ '@/views/client/newGrid/ForgotPassword'
  )
const ResetPassword = () =>
  import(
    /* webpackChunkName: "reset-password" */ '@/views/client/newGrid/ResetPassword'
  )
const AccountsList = () =>
  import(
    /* webpackChunkName: "account-list" */ '@/views/client/newGrid/account/AccountsList'
  )
const InvitationAccount = () =>
  import(
    /* webpackChunkName: "invitation-account" */ '@/views/client/newGrid/InvitationAccount'
  )

// Client onboarding
const Onboarding = () =>
  import(
    /* webpackChunkName: "onboarding" */ '@/views/client/newGrid/onboarding/Onboarding'
  )
const OnboardingSignup = () =>
  import(
    /* webpackChunkName: "onboarding-signup" */ '@/views/client/newGrid/onboarding/OnboardingSignup'
  )
const OnboardingBilling = () =>
  import(
    /* webpackChunkName: "onboarding-billing" */ '@/views/client/newGrid/onboarding/OnboardingBilling'
  )
const OnboardingPayment = () =>
  import(
    /* webpackChunkName: "onboarding-payment" */ '@/views/client/newGrid/onboarding/OnboardingPayment'
  )
const OnboardingAccountDetails = () =>
  import(
    /* webpackChunkName: "onboarding-payment" */ '@/views/client/newGrid/onboarding/OnboardingAccountDetails'
  )

// import OnboardingFreemium from '@/views/client/newGrid/onboarding/OnboardingFreemium'
import OnboardingUsage from '@/views/client/newGrid/onboarding/OnboardingUsage'

// Freemium onboarding
const OnboardingFreemium = () =>
  import(
    /* webpackChunkName: "freemium-start" */ '@/views/client/newGrid/onboarding/OnboardingFreemium'
  )
const FreemiumProfile = () =>
  import(
    /* webpackChunkName: "freemium-profile" */ '@/components/freemium/FreemiumProfile'
  )
const FreemiumConnectionForm = () =>
  import(
    /* webpackChunkName: "freemium-connection" */ '@/components/freemium/FreemiumConnectionForm'
  )
const FreemiumContentForm = () =>
  import(
    /* webpackChunkName: "freemium-content" */ '@/components/freemium/FreemiumContentForm'
  )
const FreemiumEasyOrderForm = () =>
  import(
    /* webpackChunkName: "freemium-translation" */ '@/components/freemium/FreemiumEasyOrderForm'
  )
const FreemiumTranslatorForm = () =>
  import(
    /* webpackChunkName: "freemium-translator" */ '@/components/freemium/FreemiumTranslatorForm'
  )
const FreemiumSetupConnection = () =>
  import(
    /* webpackChunkName: "freemium-setup-connection" */ '@/components/freemium/FreemiumSetupConnection'
  )

// Supplier onboarding
const SupplierOnboarding = () =>
  import(
    /* webpackChunkName: "supplier-onboarding" */ '@/views/supplier/onboarding/SupplierOnboarding'
  )
const SupplierOnboardingSignup = () =>
  import(
    /* webpackChunkName: "supplier-onboarding-signup" */ '@/views/supplier/onboarding/SupplierOnboardingSignup'
  )
const SupplierOnboardingDetails = () =>
  import(
    /* webpackChunkName: "supplier-onboarding-details" */ '@/views/supplier/onboarding/SupplierOnboardingDetails'
  )
const SupplierOnboardingWizard = () =>
  import(
    /* webpackChunkName: "supplier-onboarding-wizard" */ '@/views/supplier/onboarding/SupplierOnboardingWizard'
  )
const SupplierOnboardingServices = () =>
  import(
    /* webpackChunkName: "supplier-onboarding-services" */ '@/views/supplier/onboarding/SupplierOnboardingServices'
  )
const SupplierOnboardingSuccess = () =>
  import(
    /* webpackChunkName: "supplier-onboarding-success" */ '@/views/supplier/onboarding/SupplierOnboardingSuccess'
  )
const SupplierOnboardingPayout = () =>
  import(
    /* webpackChunkName: "supplier-onboarding-payout" */ '@/views/supplier/onboarding/SupplierOnboardingPayout'
  )

export default [
  {
    path: '/',
    redirect: `/${i18n.i18n.global.locale.value}`
  },
  {
    path: '/:locale',
    component: Base,
    children: [
      {
        path: '',
        name: 'BaseLogin',
        component: Login,
        meta: {
          isPublicRoute: true
        },
        beforeEnter: (to, from) => {
          if (from?.query?.referral === 'g2') {
            to.query = { ...to.query, referral: 'g2' }
          }
          const deeplink = from?.query?.deeplink || to?.query?.deeplink
          if (deeplink) {
            to.query = { ...to.query, deeplink: deeplink }
          }
          if (from.path !== '/' && from.name) {
            TokenService.removeAll()
          }
          return
        }
      },
      {
        path: 'outsider-redirect/customer',
        name: 'outsiderRedirect',
        component: Login,
        meta: {
          isPublicRoute: true
        },
        beforeEnter: (to, from) => {
          if (from?.query?.referral === 'g2') {
            to.query = { ...to.query, referral: 'g2' }
          }
          const deeplink = from?.query?.deeplink || to?.query?.deeplink
          if (deeplink) {
            to.query = { ...to.query, deeplink: deeplink }
          }
          if (from.path !== '/' && from.name) {
            TokenService.removeAll()
          }
          return
        }
      },
      {
        path: 'forgot-password',
        name: 'forgotPassword',
        component: ForgotPassword,
        meta: {
          isPublicRoute: true
        }
      },
      {
        path: 'reset-password/:token',
        name: 'resetPassword',
        component: ResetPassword,
        meta: {
          isPublicRoute: true
        }
      },
      {
        path: 'customer/:teamIdentifier/invitation/:token',
        name: 'invitation',
        component: InvitationAccount,
        meta: {
          isPublicRoute: true
        }
      },
      {
        path: 'accounts',
        name: 'accountsList',
        component: AccountsList,
        meta: {
          isPublicRoute: true
        }
      },
      {
        path: 'freemium-start',
        name: 'freemiumStart',
        props: true,
        component: OnboardingFreemium,
        children: [
          {
            path: '',
            name: 'freemiumProfile',
            props: true,
            component: FreemiumProfile,
            meta: {
              index: 1,
              step: '1. Freemium profile',
              baseRoute: 'freemiumStart',
              isPublicRoute: true
            }
          },
          {
            path: 'connection',
            name: 'freemiumConnection',
            component: FreemiumConnectionForm,
            meta: {
              index: 2,
              step: '2. Freemium connection',
              baseRoute: 'freemiumStart',
              isPublicRoute: true
            }
          },
          {
            path: 'setup-connection',
            name: 'freemiumSetupConnection',
            component: FreemiumSetupConnection,
            meta: {
              index: 3,
              step: '3. Freemium set up connection',
              baseRoute: 'freemiumStart',
              isPublicRoute: true
            }
          },
          {
            path: 'content',
            name: 'freemiumContent',
            component: FreemiumContentForm,
            meta: {
              index: 4,
              step: '4. Freemium content form',
              baseRoute: 'freemiumStart',
              isPublicRoute: true
            }
          },
          {
            path: 'translation',
            name: 'freemiumEasyOrderFlow',
            component: FreemiumEasyOrderForm,
            meta: {
              index: 5,
              step: '5. Freemium translation form',
              baseRoute: 'freemiumStart',
              isPublicRoute: true
            }
          },
          {
            path: 'translators',
            name: 'freemiumTranslator',
            component: FreemiumTranslatorForm,
            meta: {
              index: 6,
              step: '6. Freemium translator form',
              baseRoute: 'freemiumStart',
              isPublicRoute: true
            },
            beforeEnter: (to, from) => {
              if (
                from.name == 'contentGenerationV2' ||
                from.name == 'clientMarketplace' ||
                from.name == 'clientOverview'
              ) {
                return false
              }
            }
          }
        ],
        meta: {
          isPublicRoute: true
        }
      },
      {
        path: 'usage-start',
        name: 'usageStart',
        component: OnboardingUsage,
        meta: {
          isPublicRoute: true
        }
      },
      {
        path: 'onboarding',
        component: Onboarding,
        children: [
          {
            path: '',
            name: 'onboardingSignup',
            component: OnboardingSignup,
            meta: {
              index: 1,
              step: '1. Sign up',
              baseRoute: 'onboarding',
              isPublicRoute: true
            }
          },
          {
            path: 'account-details',
            name: 'onboardingAccountDetails',
            component: OnboardingAccountDetails,
            meta: {
              index: 2,
              step: '2. Enter account details',
              baseRoute: 'onboarding',
              isPublicRoute: true
            }
          },
          {
            path: 'billing',
            name: 'onboardingBilling',
            component: OnboardingBilling,
            meta: {
              index: 3,
              step: '3. Enter billing details',
              baseRoute: 'onboarding',
              isPublicRoute: true
            }
          },
          {
            path: 'payment',
            name: 'onboardingPayment',
            component: OnboardingPayment,
            meta: {
              index: 4,
              step: '4. Enter payment details',
              baseRoute: 'onboarding',
              isPublicRoute: true
            }
          }
        ]
      },
      {
        path: 'supplier/onboarding/:id',
        component: SupplierOnboarding,
        children: [
          {
            path: '',
            name: 'supplierOnboardingSignup',
            component: SupplierOnboardingSignup,
            meta: {
              isPublicRoute: true
            }
          },
          {
            path: 'wizard',
            name: 'supplierOnboardingWizard',
            component: SupplierOnboardingWizard,
            meta: {
              isPublicRoute: true
            },
            children: [
              {
                path: 'about-you',
                name: 'supplierOnboardingDetails',
                component: SupplierOnboardingDetails,
                meta: {
                  isPublicRoute: true
                }
              },
              {
                path: 'services',
                name: 'supplierOnboardingServices',
                component: SupplierOnboardingServices,
                meta: {
                  isPublicRoute: true
                }
              },
              {
                path: 'payout',
                name: 'supplierOnboardingPayout',
                component: SupplierOnboardingPayout,
                meta: {
                  isPublicRoute: true
                }
              },
              {
                path: 'success',
                name: 'supplierOnboardingSuccess',
                component: SupplierOnboardingSuccess,
                meta: {
                  isPublicRoute: true
                }
              }
            ]
          }
        ]
      }
    ]
  },
  {
    path: '/:pathMatch(.*)*',
    redirect: {
      path: `/${i18n.i18n.global.locale.value}`
    }
  }
]
